<script lang="ts">
    import welcome_title from '$lib/images/DiGroup-AI.png';
    import { marked } from 'marked';
    import type { PolygonCredential } from '$lib/types/credential';

    let message = `Some words are *italic*, some are **bold**\n- lists\n- are\n- cool`;
    let credentialJson = `Paste your polygon credential in JSON here`;
    let generatedLink: string = "";
    let errorMessage: string = "";

    async function createLink() {
    try {
        // Dynamically import SchnorrSystem only when needed to avoid web-worker problems in Clouflare
        const { SchnorrSystem } = await import('$lib/circuits/schnorr');
        const schnorr = new SchnorrSystem();

        // Parse the credential
        const credential: PolygonCredential = JSON.parse(credentialJson);

        // Sign the message using the credential
        const signature = await schnorr.signWithCredential(message, credential);

        // Generate the proof
        const proof = await schnorr.verify(message, signature, credential);

        // Store only what's needed
        const data = {
            message,
            credential
        };

        const response = await fetch('/api/store-message', {
            method: 'POST',
            headers: {
                'Content-Type': 'application/json'
            },
            body: JSON.stringify(data)
        });

        if (response.ok) {
            const result = await response.json();
            const id = result.id;
            console.log("The store-message API was called and id returned = " + id);

            // Also store proof in PROOF_STORE for later blockchain usage
            await fetch('/api/store-proof', {
                method: 'POST',
                headers: {
                    'Content-Type': 'application/json'
                },
                body: JSON.stringify({
                    id,
                    proof,
                    signature
                })
            });

            const baseUrl = import.meta.env.VITE_BASE_URL || import.meta.env.CF_PAGES_URL;
            console.log("baseUrl=", baseUrl);
            generatedLink = `${baseUrl}/reader/${id}`;
        } else {
            console.error('Failed to store message:', response.statusText);
        }
        errorMessage = "";
    } catch (error) {
        errorMessage = (typeof error === 'string') 
            ? error as string 
            : JSON.stringify(error, Object.getOwnPropertyNames(error));
        console.error('Error:', error);
    }
}
</script>

<svelte:head>
    <title>Home</title>
    <meta name="description" content="Svelte demo app" />
</svelte:head>

<section>
    <span class="welcome">
        <picture>
            <img src={welcome_title} alt="Welcome" />
        </picture>
    </span>

    <div class="container">
        <h2>Enter a message:</h2>
        <textarea id="message-text" bind:value={message}></textarea>
    </div>

    <div class="container">
        <div>{@html marked(message)}</div>
    </div>

    <div class="container">
        <h2>Your credential:</h2>
        <textarea id="credential-json" bind:value={credentialJson}></textarea>
        {#if errorMessage}
            <div class='error'>{errorMessage}</div>
        {/if}
    </div>

    <div class="container">
        <button on:click={createLink}>Create link</button>
        <div class="link-container">
            {#if generatedLink}
                <a href={generatedLink} target="_blank">{generatedLink}</a>
            {:else}
                <p>The generated link will appear here</p>
            {/if}
        </div>
    </div>
</section>


<style>
    @import '../styles/shared.css';

    .link-container {
        position: relative;
        width: 100%;
        padding: 10px;
        text-align: left;
    }

    button {
        width: 100px;
    }

    textarea {
        width: 100%;
        height: 150px;
        padding: 10px;
        border: 1px solid #ccc;
        border-radius: 4px;
        font-size: 16px;
        resize: none;
        box-shadow: inset 0 1px 3px rgba(0, 0, 0, 0.1);
    }

    textarea:focus {
        border-color: #007bff;
        outline: none;
        box-shadow: 0 0 5px rgba(0, 123, 255, 0.5);
    }

    .error {
        color: red;
        padding: 10px;
        font-size: 16px;
    }

    .welcome {
        display: block;
        position: relative;
        width: 100%;
        height: 0;
        padding: 0 0 calc(100% * 495 / 2048) 0;
    }

    .welcome img {
        position: absolute;
        width: 100%;
        height: 100%;
        top: 0;
        display: block;
    }
</style>
